import Routerx from '@/routerx'
import { http } from '@/utils'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { App, Breadcrumb, Button, Card, DatePicker, Flex, Form, Input, Select, Space, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

const { Title } = Typography

const { Option } = Select

interface TypeType {
  name: string
  value: number
}

const typeArr: TypeType[] = [
  {
    name: '无',
    value: 0,
  },
  {
    name: '单增',
    value: 1,
  },
  {
    name: '双增',
    value: 2,
  },
  {
    name: '黄雀',
    value: 3,
  },
]

interface StatusType {
  name: string
  value: number
}

const statusArr: StatusType[] = [
  {
    name: '进行中',
    value: 1,
  },
  {
    name: '已停止',
    value: 2,
  },
]

interface FormValuesType {
  id: number
  name: string
  the_type: number
  status: number
  remark: string
  created_at: string
  deadline: string
  end_at: string
}

interface ParamsType {
  name: string
  type: number
  status: number
  remark: string
  created_at: string
  deadline: string
  end_at: string
}

const BatchPublish = () => {
  const { message } = App.useApp()
  const params = useParams()
  const batchId = Number(params.id)
  const [content, setContent] = useState('')

  const onFinish = async (values: FormValuesType) => {
    const { id, name, the_type, status, remark, created_at, deadline, end_at } = values
    const params: ParamsType = {
      name,
      type: the_type,
      status,
      remark,
      created_at: '',
      deadline: '',
      end_at: '',
    }
    if (created_at != null) {
      params.created_at = dayjs(created_at).format('YYYY-MM-DD 00:00:00')
    }
    if (deadline != null) {
      params.deadline = dayjs(deadline).format('YYYY-MM-DD 00:00:00')
    }
    if (end_at != null) {
      params.end_at = dayjs(end_at).format('YYYY-MM-DD 00:00:00')
    }
    try {
      if (batchId) {
        // 编辑
        await http.post(`/v1/batch/update`, { ...params, id: batchId })
      } else {
        // 新增
        await http.post('/v1/batch/create', params)
      }
      message.success(`${id ? '更新成功' : '发布成功'}`)
      Routerx.navigate('/batch')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // 处理axios错误
        message.error(error.response?.data?.message)
      } else {
        // 处理非axios错误
        console.error('Unexpected error:', error)
      }
      return null
    }
  }

  const [form] = Form.useForm()
  useEffect(() => {
    async function getBatch() {
      const res = await http.post(`/v1/batch/get`, { id: batchId })
      const { type, created_at, deadline_str, end_at, ...formValue } = res.data
      const new_end_at = end_at != '' ? dayjs(end_at, 'YYYY/MM/DD') : null
      form.setFieldsValue({ ...formValue, the_type: type, created_at: dayjs(created_at, 'YYYY/MM/DD'), deadline: dayjs(deadline_str, 'YYYY/MM/DD'), end_at: new_end_at })
    }
    if (batchId) {
      getBatch()
    }
  }, [batchId, form])

  const pageTitle = batchId ? '编辑' : '新建'

  return (
    <div className="publish">
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              title: '股池',
            },
            {
              title: <Link to="/batch">批次列表</Link>,
            },
            {
              title: pageTitle,
            },
          ]}
        />
        <Title
          level={4}
          style={{ margin: '5px 0 10px 0' }}
        >
          <Flex
            justify="flex-start"
            align="center"
            gap="small"
            vertical={false}
          >
            {pageTitle}
            <Button
              type="dashed"
              shape="circle"
              size="small"
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: '16px' }}
              onClick={() => Routerx.navigate(`/batch`)}
            ></Button>
          </Flex>
        </Title>
        <Card>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ type: 1 }}
            onFinish={onFinish}
            form={form}
          >
            {batchId ? (
              <Form.Item
                label="ID"
                name="id"
              >
                <Input
                  style={{ width: 400 }}
                  disabled={true}
                />
              </Form.Item>
            ) : null}
            <Form.Item
              label="名称"
              name="name"
              rules={[{ required: true, message: '请输入批次名称' }]}
            >
              <Input
                placeholder="请输入文章标题"
                style={{ width: 400 }}
              />
            </Form.Item>
            <Form.Item
              label="类型"
              name="the_type"
              rules={[{ required: true, message: '请选择批次类型' }]}
            >
              <Select
                placeholder="请选择类型"
                style={{ width: 400 }}
              >
                {typeArr.map((item: TypeType) => (
                  <Option
                    key={item.value}
                    value={item.value}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="状态"
              name="status"
              rules={[{ required: true, message: '请选择批次状态' }]}
            >
              <Select
                placeholder="请选择状态"
                style={{ width: 400 }}
              >
                {statusArr.map((item: StatusType) => (
                  <Option
                    key={item.value}
                    value={item.value}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="created_at"
              label="开始日期"
              rules={[{ type: 'object' as const, required: true, message: '请选择开始日期' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="deadline"
              label="截止日期"
              rules={[{ type: 'object' as const, required: true, message: '请选择开始日期' }]}
            >
              <DatePicker />
            </Form.Item>
            {batchId ? (
              <Form.Item
                name="end_at"
                label="结束日期"
              >
                <DatePicker />
              </Form.Item>
            ) : null}
            <Form.Item
              label="备注"
              name="remark"
            >
              <TextArea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="请输入备注"
                rows={4}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4 }}>
              <Space>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {batchId ? '修改批次' : '添加批次'}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Flex>
    </div>
  )
}

export default BatchPublish
